var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex justify-between items-center mb-4"},[_c('div',{staticClass:"title is-4"},[_vm._v("Products")]),_c('router-link',{attrs:{"to":"/products/new","tag":"button"}},[_c('b-button',{attrs:{"type":"is-success"}},[_vm._v("Create Product")])],1)],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var refine = ref.refine;
return _c('b-input',{attrs:{"icon":"magnify","placeholder":"Filter products..."},on:{"input":function($event){return refine($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})}}])},[(_vm.selectedProducts.length)?_c('div',{staticClass:"flex items-center bg-gray-100 p-2 rounded-md border-solid border-gray-200 border-1"},[_c('div',{staticClass:"text-sm font-semibold mr-2"},[_vm._v(" "+_vm._s(_vm.selectedProducts.length)+" selected ")]),_c('b-button',{attrs:{"type":"mr-2"}},[_vm._v("Archive")]),_c('div',{staticClass:"text-sm ml-auto underline cursor-pointer"},[_vm._v(" Select all products in store ")])],1):_vm._e(),_c('b-table',{staticClass:"text-sm mb-2 cursor-pointer",attrs:{"data":_vm.rows,"default-sort":['title', 'asc'],"custom-is-checked":_vm.isRowSelected,"checkbox-position":"left","checkable":"","hoverable":"","row-class":function () { return _vm.$style.row; }},on:{"sort":_vm.handleSort,"click":_vm.viewProduct,"check":_vm.setSelectedProducts}},[_c('b-table-column',{attrs:{"field":"title","label":"Title","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"h-16",attrs:{"src":row.imageUrl}}),_c('span',{staticClass:"font-bold m-3"},[_vm._v(_vm._s(row.title))])])]}}])}),_c('b-table-column',{attrs:{"field":"quantity","label":"Inventory"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isQuantityTracked(row))?[(_vm.isQuantityZero(row))?_c('span',{class:_vm.$style.zero},[_vm._v("0")]):_c('span',[_vm._v(" "+_vm._s(_vm.getTotalVariantsQuantity(row.variants))+" ")]),_vm._v(" in stock "),_c('span',[_vm._v(_vm._s(_vm.getVariantText(row)))])]:_c('div',{staticClass:"text-gray-500"},[_vm._v("Inventory not tracked")])]}}])}),_c('b-table-column',{attrs:{"field":"type","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getProductType(row.variants))+" ")]}}])})],1),_c('b-pagination',{attrs:{"current":_vm.currentPage,"per-page":_vm.itemsPerPage,"total":_vm.nbHits,"order":"is-right","simple":""},on:{"update:current":function($event){_vm.currentPage=$event}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }