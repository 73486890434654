











































































import mixins from 'vue-typed-mixins'
import debounce from 'just-debounce-it'
import { getElasticSearchClient } from '@/services/elasticSearch'
import QueryParamsMixin from '@/mixins/QueryParams.mixin'
import ProductsTableMixin from '@/mixins/ProductsTable.mixin'

enum ProductType {
  Physical = 'physical',
  Virtual = 'virtual',
}

interface Row {
  productId: string
  title: string
  variants: {
    productId: string
    variantId: string
    title: string
    trackQuantity: boolean
    variantType: string
    quantity: number
  }[]
  createdAt: Date
  updatedAt: Date
}

export default mixins(ProductsTableMixin, QueryParamsMixin).extend({
  name: 'Products',
  mixins: [QueryParamsMixin],
  data() {
    return {
      searchInput: '',
      searchResult: [] as Record<string, any>,
      itemsPerPage: 25,
      selectedProducts: [] as any[],
    }
  },
  computed: {
    query(): Record<string, any> {
      const query: Record<string, any> = {
        query: {
          bool: {
            filter: [],
            must: [],
            // must_not: [],
          },
        },
        track_total_hits: true,
        sort: this.sort,
        size: this.itemsPerPage,
        from: (this.currentPage - 1) * this.itemsPerPage,
      }

      if (this.search && this.search.length > 0) {
        query.query.bool.filter.push({
          match_phrase: { title: this.search },
        })
      }

      // if (this.status.length > 0) {
      //   query.query.bool.filter.push({
      //     terms: { status: this.status },
      //   })
      // }

      // if (this.discountCodeType.length > 0) {
      //   query.query.bool.filter.push({
      //     terms: { ruleType: this.discountCodeType },
      //   })
      // }

      // if (this.timeUsed.from.length > 0) {
      //   query.query.bool.must.push({
      //     range: {
      //       usage: {
      //         gte: this.timeUsed.from,
      //       },
      //     },
      //   })
      // }

      // if (this.timeUsed.to.length > 0) {
      //   query.query.bool.must.push({
      //     range: {
      //       usage: {
      //         lte: this.timeUsed.to,
      //       },
      //     },
      //   })
      // }

      // if (this.timeUsed.equal.length > 0) {
      //   query.query.bool.must.push({
      //     term: {
      //       usage: this.timeUsed.equal,
      //     },
      //   })
      // }

      // if (this.timeUsed.notEqual.length > 0) {
      //   query.query.bool.must_not.push({
      //     term: {
      //       usage: this.timeUsed.notEqual,
      //     },
      //   })
      // }

      // if (this.dateRange.length > 0) {
      //   query.query.bool.filter.push({
      //     range: {
      //       startsAt: {
      //         format: 'strict_date_optional_time',
      //         gte: this.dateRange[0],
      //         lte: this.dateRange[1],
      //       },
      //     },
      //   })
      // }

      if (
        query.query.bool.filter.length === 0 &&
        query.query.bool.must.length === 0
      ) {
        query.query = {
          match_all: {},
        }
      }

      return query
    },
    nbHits(): number {
      return this.searchResult && this.searchResult.hits
        ? this.searchResult.hits.total.value
        : 0
    },
    rows(): Row[] {
      if (!this.searchResult || this.searchResult.length === 0) {
        return []
      }
      return this.searchResult.hits.hits.map((h: { _source: Row }) => h._source)
    },
  },
  watch: {
    searchInput: {
      immediate: true,
      handler: debounce(function () {
        //@ts-ignore
        this.search = this.searchInput.trim()
      }, 500),
    },
    query: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    debouncedSearchInput: debounce(function (this: any, value: string) {
      this.search = value
    }, 500),
    async fetchData(): Promise<void> {
      const elasticSearch = getElasticSearchClient()
      const { data } = await elasticSearch.post(
        '/inventory_products/_search',
        this.query
      )
      this.searchResult = data
    },
    viewProduct(product: Record<string, any>) {
      this.$router.push(`/products/${product.productId}`)
    },
    getVariantText(data: Record<string, any>) {
      const { variants } = data
      if (variants && variants.length > 0) {
        return `for ${variants.length} ${
          variants.length > 1 ? 'variants' : 'variant'
        }`
      } else {
        return ''
      }
    },
    // TODO: types
    setSelectedProducts(checkedProducts: any[]) {
      this.selectedProducts = checkedProducts
    },
    // TODO: types
    isRowSelected(a: any, b: any) {
      return a.productId === b.productId
    },
    // TODO: types
    getTotalVariantsQuantity(variants: any[]) {
      return variants.reduce((total, variant) => {
        if (variant.trackQuantity) {
          return (total += variant.quantity)
        }

        return total
      }, 0)
    },
    isQuantityZero(product: any) {
      return this.getTotalVariantsQuantity(product.variants) === 0
    },
    // TODO: types
    getProductType(variants: any[]) {
      if (variants.every(this.isTypePhysical)) {
        return 'Physical'
      }

      if (variants.every(this.isTypeVirtual)) {
        return 'Virtual'
      }

      return 'Mixed'
    },
    isTypePhysical(variant: Record<string, any>) {
      return variant.type === ProductType.Physical
    },
    isTypeVirtual(variant: Record<string, any>) {
      return variant.type === ProductType.Virtual
    },
    isQuantityTracked(product: any) {
      return product.variants.some((variant: any) => variant.trackQuantity)
    },
  },
})
